export default function ShowError(){
    return (
        <div style={{ width: "420px", margin: "auto", textAlign: "center"}}>
            <div className="d-flex justify-content-center align-items-center" id="main">
                <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
                <div className="inline-block align-middle">
                    <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
                </div>
            </div>
            <a href="/" className="btn btn-primary">Back to home</a>
        </div>
    )
}