import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTags } from "../store/tagSlice";
import Loading from "./Loading";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

export default function Tags(){
    const [selectedTag, setSelectedTag] = useState([]);
    const dispatch = useDispatch();
    const { entities, loading, error } = useSelector(state=>state.tags);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        dispatch(getTags());
        const queryParams = new URLSearchParams(location.search);
        const tagsQuery = queryParams.get('tags');
        if(tagsQuery){
          setSelectedTag(tagsQuery);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch]);
    
    if (loading) {
      return <Loading />;
    }
    if (error) {
        return <div>Oops... {error}</div>;
    }

    const addQueryParamAndNavigate = (param) => {
      const searchParams = new URLSearchParams(location.search);
      if(searchParams.get('page')){
        searchParams.delete('page');
      }
      if (searchParams.get('tags')) {
        let prevParams = searchParams.get('tags').split(',');
    
        if (prevParams.includes(param)) {
            prevParams = prevParams.filter(tag => tag !== param);
        } else {
            prevParams.push(param);
        }
    
        if (prevParams.length === 0) {
            searchParams.delete('tags');
        } else {
            searchParams.set('tags', prevParams.join(','));
        }
        setSelectedTag(prevParams.join(','));
      } else {
          searchParams.set('tags', param);
          setSelectedTag(elem => [...elem, param]);
      }
    
      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    };

    return(
        <>
          <h2 className="h6 text-start">Browse by tags</h2>
            {
              entities.total > 0 && entities.tags.map((tag,i)=>{
                return (
                    <Button variant="outline-secondary" onClick={()=>addQueryParamAndNavigate(tag.value)} size="sm" key={i} className={(selectedTag.includes(tag.value)?"m-1 rounded-5 active":"m-1 rounded-5")} title={`${tag.verses_count} verses`}>
                      {tag.value}
                    </Button>
                );
              })
            }
        </>
    );
}