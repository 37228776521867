import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    loading: false,
    entities: {
        total: 0,
        categories: []
    },
    error: null
}

export const getCategories = createAsyncThunk('getCategories', async (param)=>{
    let url = `${process.env.REACT_APP_API_URL}/categories`;
    if(param){
        url+=`?${param.toString()}`;
    }
    const response = await axios.get(url).then(res => res.data);
    return response.data;
}) 

const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers:{
        categoryAdded(state, action) {
            state.entities.categories.unshift(action.payload);
            state.entities.total+=1;
        },
        categoryDeleted(state, action) {
            const { id } = action.payload;
            const existingCategory = state.entities.categories.find((elem) => elem.id === id);
            if (existingCategory) {
              state.entities.categories = state.entities.categories.filter((elem) => elem.id !== id);
              state.entities.total-=1;
            }
          },
    },
    extraReducers:(builder)=>{
        builder.addCase(getCategories.pending, (state)=>{
            state.loading = true;
        });
        builder.addCase(getCategories.fulfilled, (state, action)=>{
            state.loading = false;
            state.entities = action.payload;
            state.error = null;
        });
        builder.addCase(getCategories.rejected, (state, action)=>{
            state.loading = false;
            state.entities = {
                total: 0,
                categories: []
            };
            state.error = action.error.message
        });
    }
});

export const {categoryAdded, categoryUpdated, categoryDeleted} = categorySlice.actions;
export default categorySlice.reducer;