import {Container, Button} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/logo.png';
import { useAuth0 } from "@auth0/auth0-react";
import AuthorizedMenuItem from './AuthorizedMenuItem';

function Navigation() {
    const navigate = useNavigate();
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
      } = useAuth0();
    const logoutWithRedirect = ()=>{
        logout({
            logoutParams: {
              returnTo: window.location.origin,
            }
        });
        localStorage.clear();
    }
    return (
        <>
        <Navbar bg="dark" variant='dark' expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/"><img src={logo} width="40" alt="logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    {isAuthenticated && <Nav.Link onClick={()=>navigate('/', {state:{my_verse: true}})}>My Verse</Nav.Link>}
                    {!isAuthenticated && <Nav.Link onClick={()=>loginWithRedirect()}>My Verse</Nav.Link>}
                </Nav>
                    {/* <Form className="d-flex">
                        <InputGroup className="mx-3" size="sm" style={{"minWidth":400}}>
                            <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="search"
                            />
                            <Button variant="outline-secondary" size="sm" id="button-search">
                            <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Form> */}
                <Nav>
                    <Nav.Link><Button type='button' variant="outline-secondary" size="sm" className="mx-4" onClick={()=>(isAuthenticated)?navigate('/verses/create'): loginWithRedirect({
                        appState: {
                            returnTo: '/verses/create'
                        }
                    })}>Add New Verse</Button></Nav.Link>
                    {
                        !isAuthenticated && <Nav.Link><Button variant="outline-info" size="sm" className="mx-2" onClick={() => loginWithRedirect({})}>Login</Button></Nav.Link>
                    }
                    {
                        isAuthenticated && 
                        <NavDropdown title={<><img
                            src={user.picture}
                            alt="Profile"
                            className="nav-user-profile rounded-circle"
                            width="30"
                          /> {user.name}</>} id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                            <AuthorizedMenuItem to="/admin/dashboard">Admin Panel</AuthorizedMenuItem>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>logoutWithRedirect()}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    }
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );
}

export default Navigation;