import { Container, Row, Col } from "react-bootstrap";
import VersesList from "./Verses/List";
import Category from "./Category";
import Tags from "./Tags";

export default function Dashboard(){
    return(
        <Container className="mx-auto main-container">
            <Row className="mt-2">
                <Col md={9}>
                    <VersesList/>
                </Col>
                <Col md={3}>
                    <Category/>
                    <div className="mt-3 mb-3 text-center">
                        <a href="https://dusp.org/?a_aid=64c50d5647165&a_bid=4f9e1e24" target="_blank" rel="noreferrer"><img src="//dusp.org/PAP/accounts/default1/banners/4f9e1e24.gif" alt="Visit Dar-us-Salam.com for all your Islamic shopping needs!" title="Visit Dar-us-Salam.com for all your Islamic shopping needs!" width="95%" height="60" /></a><img style={{"border":0}} src="https://dusp.org/PAP/scripts/imp.php?a_aid=64c50d5647165&a_bid=4f9e1e24" width="1" height="1" alt="" />
                    </div>
                    <Tags/>
                    <div className="mt-3">
                        <table border="1" align="center" cellPadding="5">
                            <tbody>
                                <tr>
                                    <td bgcolor="#FFFFFF">
                                        <div align="center"><em><strong>The Most Comprehensive and Authentic Explaination of the Quran!</strong></em><br/><a href="https://dusp.org/quran/tafseer/q09-tafsir-ibn-kathir.html?a_aid=64c50d5647165&a_bid=a350668f" target="_blank" rel="noreferrer"><img src="https://dusp.org/703-medium_default/q09-tafsir-ibn-kathir.jpg" alt="Tafsir Ibn Kahtir (10 Vol)" width="125" height="94" border="0"/></a><br/><a href="https://dusp.org/quran/tafseer/q09-tafsir-ibn-kathir.html?a_aid=64c50d5647165&a_bid=a350668f" target="_blank" rel="noreferrer"><font size="2">Purchase the complete Tafsir Ibn Kathir (10 Vol Abridged) By Dar-us-Salam Publications.</font></a></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}