import { Button, Table } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import Notification from '../Notification';
import useFetch from '../useFetch';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTrash } from "@fortawesome/free-solid-svg-icons";

export function Verses(props){
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [message, setMessage] = useState("");
    const [verifiedVerses, setVerifiedVerses] = useState([]);
    const [unverifiedVerses, setUnverifiedVerses] = useState([]);
    const {data, isPending, error} = useFetch(`${process.env.REACT_APP_API_URL}/admin/verses?sort_by=id`, refresh);

    useEffect(()=>{
        if(data){
            const verses = data.verses;
            setVerifiedVerses(verses.filter(elem=>elem.verified==="1"));
            setUnverifiedVerses(verses.filter(elem=>elem.verified==="0"));
        }
    },[data]);

    if(error){
        return <div>Oops! An error occurred.</div>;
    }

    if(isPending){
        return <Loading/>;
    }

    const handleDelete = async(id) => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading1(true);
            fetch(`${process.env.REACT_APP_API_URL}/admin/verses/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading1(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    setRefresh(new Date().getTime());
                    props.setRefresh(new Date().getTime());
                });
        } catch (error) {
            // console.log(error);
            setIsLoading1(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    };

    const handleVerify = async (verse)=>{
        try {
            const token = await getAccessTokenSilently();
            setIsLoading2(true);
            fetch(`${process.env.REACT_APP_API_URL}/admin/verses/${verse.id}/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                },
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading2(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    setRefresh(new Date().getTime());
                    props.setRefresh(new Date().getTime());
                });
        } catch (error) {
            setIsLoading2(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    }

    return (
        <>
        {showMessage && <Notification show={showMessage} close={()=>setShowMessage(false)} message={message}/>}
        <Table className="mt-3" bordered>
            <thead>
                <tr>
                    <th width="5%">SN</th>
                    <th width="75%">Verse</th>
                    <th width="20%">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    unverifiedVerses && unverifiedVerses.map((verse,i)=>{
                        return (
                            <tr key={i}>
                                <td>{++i}</td>
                                <td>
                                    <p><b>{verse.title}</b></p>
                                    <div dangerouslySetInnerHTML={{ __html: verse.description }}></div>
                                    <span><b>Category: </b>{verse.category.name}</span><br/>
                                    <span><b>Tags: </b>{JSON.stringify(verse.tags.map(elem=>elem.value))}</span>
                                </td>  
                                <td>
                                    <Button size='sm' variant="outline-success" onClick={()=>handleVerify(verse)} disabled={isLoading2?"disabled":""}><FontAwesomeIcon icon={faCheckSquare}/> Verify</Button>
                                    <span> </span>
                                    <Button size='sm' variant='outline-danger' onClick={()=>handleDelete(verse.id)} title="Delete" disabled={isLoading1?"disabled":""}><FontAwesomeIcon icon={faTrash}/></Button>
                                </td>
                            </tr>
                        )
                    })
                }
                <tr key={-1}><td colSpan={3}>----------</td></tr>
                {
                    verifiedVerses && verifiedVerses.map((verse,i)=>{
                        return (
                            <tr key={i}>
                                <td>{++i}</td>
                                <td>
                                    <p><b>{verse.title}</b></p>
                                    <div dangerouslySetInnerHTML={{ __html: verse.description }}></div>
                                    <span><b>Category: </b>{verse.category.name}</span><br/>
                                    <span><b>Tags: </b>{JSON.stringify(verse.tags.map(elem=>elem.value))}</span>
                                </td>  
                                <td className="text-center">
                                    <Button size='sm' variant='outline-danger' onClick={()=>handleDelete(verse.id)}  title="Delete" disabled={isLoading1?"disabled":""}><FontAwesomeIcon icon={faTrash}/></Button>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
        </>
    )
}