import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    loading: false,
    entities: {
        total:0,
        tags:[]
    },
    error: null
}

export const getTags = createAsyncThunk('getTags', async (param)=>{
    let url = `${process.env.REACT_APP_API_URL}/tags?limit=20`;
    if(param){
        url+=`?${param.toString()}`;
    }
    const response = await axios.get(url).then(res => res.data);
    return response.data;
}) 

const tagSlice = createSlice({
    name: 'tags',
    initialState,
    reducers:{
        tagAdded(state, action) {
            state.entities.tags.unshift(action.payload);
            state.entities.total+=1;
        },
        tagDeleted(state, action) {
            const { id } = action.payload;
            const existingTag = state.entities.tags.find((elem) => elem.id === id);
            if (existingTag) {
              state.entities.tags = state.entities.tags.filter((elem) => elem.id !== id);
              state.entities.total-=1;
            }
          },
    },
    extraReducers:(builder)=>{
        builder.addCase(getTags.pending, (state)=>{
            state.loading = true;
        });
        builder.addCase(getTags.fulfilled, (state, action)=>{
            state.loading = false;
            state.entities = action.payload;
            state.error = null;
        });
        builder.addCase(getTags.rejected, (state, action)=>{
            state.loading = false;
            state.entities = {
                total:0,
                tags:[]
            };
            state.error = action.error.message
        });
    }
});

export const {tagAdded, tagUpdated, tagDeleted} = tagSlice.actions;
export default tagSlice.reducer;