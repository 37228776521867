import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import ShowError from './Error';

const withPermission = (WrappedComponent, requiredPermission, show=false) => {
  const AuthorizedComponent = (props) => {
    const [error, setError] = useState(true);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(()=>{
      const checkPermission = async()=>{
        if(isAuthenticated){
          try {
            const token = await getAccessTokenSilently();
            const userPermissions = jwtDecode(token).permissions;

            if (userPermissions.includes(requiredPermission)) {
              setError(false);
            }
          } catch (error) {
            console.error('Error retrieving access token:', error);
          }
        }
      };
      checkPermission();
    },[getAccessTokenSilently, isAuthenticated]);

    if (show && error) {
      return <ShowError/>;
    }
    if(!show && error){
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return AuthorizedComponent;
};

export default withPermission;
