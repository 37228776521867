import { Badge, Card, Col, Container, Nav, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Verses } from "./Verses";
import { useEffect, useState } from "react";
import { CategoriesList } from "./Categories";
import { TagsList } from "./Tags";
import { SubscriptionsList } from "./Subscriptions";
import withPermission from '../withPermission';
import useFetch from "../useFetch";
import Loading from "../Loading";

const AdminDashboard = ()=>{
    const [param, setParam] = useState("");
    const location = useLocation();
    const [refresh, setRefresh] = useState(new Date().getTime());
    const {data, isPending, error} = useFetch(`${process.env.REACT_APP_API_URL}/admin/info`, refresh);
  
    useEffect(()=>{
        if(location.pathname==="/admin/dashboard"){
            setParam("dashboard");
        }else if(location.pathname==="/admin/verses"){
            setParam("verses");
        }else if(location.pathname==="/admin/categories"){
            setParam("categories");
        }else if(location.pathname==="/admin/tags"){
            setParam("tags");
        }else if(location.pathname==="/admin/subscriptions"){
            setParam("subscriptions");
        }else{
            setParam("dashboard");
        }
        
    },[location.pathname]);

    if(error){
        return <div>Opps! An error occurred.</div>;
    }

    if(isPending){
        return <Loading/>;
    }

    return (
        <Container className="main-container">
            <Row className="mt-3">
                <Col md={2} style={{ textAlign: 'start', margin: 0, padding: 0, border: "1px solid lightgrey"}}>
                    <Nav className="flex-column">
                        <Nav.Link style={{ borderBottom: "1px solid lightgrey"}} as={Link} to="/admin/dashboard">Dashboard</Nav.Link>
                        <Nav.Link style={{ borderBottom: "1px solid lightgrey"}} as={Link} to="/admin/verses">Verses</Nav.Link>
                        <Nav.Link style={{ borderBottom: "1px solid lightgrey"}} as={Link} to="/admin/categories">Categories</Nav.Link>
                        <Nav.Link style={{ borderBottom: "1px solid lightgrey"}} as={Link} to="/admin/tags">Tags</Nav.Link>
                        <Nav.Link style={{ borderBottom: "1px solid lightgrey"}} as={Link} to="/admin/subscriptions">Subscriptions</Nav.Link>
                    </Nav>
                </Col>
                <Col md={10} style={{height: "80vh", overflowY: "scroll", border: "1px solid lightgrey"}}>
                {
                    param === "dashboard" && <Row className='m-3'>
                        <Card as={Col} className='mx-1'>
                            <Card.Body className='text-center text-decoration-none' as={Link} to="/admin/verses">
                                <Card.Title>Verses <Badge bg="secondary">{data && data.unverified_verses} new</Badge></Card.Title>
                                <Card.Text>{data && data.verses}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card as={Col} className='mx-1'>
                            <Card.Body className='text-center'>
                                <Card.Title>Categories</Card.Title>
                                <Card.Text>{data && data.categories}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card as={Col} className='mx-1'>
                            <Card.Body className='text-center'>
                                <Card.Title>Tags</Card.Title>
                                <Card.Text>{data && data.tags}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card as={Col} className='mx-1'>
                            <Card.Body className='text-center'>
                                <Card.Title>Subscriptions</Card.Title>
                                <Card.Text>{data && data.subscriptions}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                }
                {
                    param === "verses" && <Verses setRefresh={setRefresh}/>
                }
                {
                    param === "categories" && <CategoriesList setRefresh={setRefresh}/>
                }
                {
                    param === "tags" && <TagsList setRefresh={setRefresh}/>
                }
                {
                    param === "subscriptions" && <SubscriptionsList setRefresh={setRefresh}/>
                }
                </Col>
            </Row>
        </Container>
    )
}
export default withPermission(AdminDashboard, 'is:admin', true);