import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loading from "./Loading";
import { Alert, Col, Container, Row } from "react-bootstrap";

export function Subscription(){
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        try{
            fetch(`${process.env.REACT_APP_API_URL}/unsubscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email})
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                });
        } catch (error) {
            setIsLoading(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    }, [location.search])

    return(
        <Container className="main-container">
            <Row className="mt-3">
                <Col md={6} className="mx-auto">
                    <div>
                        { isLoading && <Loading/>}
                        { showMessage && <Alert variant="success">{message}</Alert> }
                        <a href="/" className="text-decoration-none">Go to home</a>
                    </div>
                </Col>
            </Row>
        </Container>   
    )
}