import { Button, Table } from "react-bootstrap";
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import Notification from '../Notification';
import useFetch from '../useFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export function TagsList(props){
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [refresh, setRefresh] = useState(new Date().getTime());
    const {data, isPending, error} = useFetch(`${process.env.REACT_APP_API_URL}/admin/tags`, refresh);

    const handleDelete = async(id) => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_API_URL}/admin/tags/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    setRefresh(new Date().getTime());
                    props.setRefresh(new Date().getTime());
                });
        } catch (error) {
            // console.log(error);
            setIsLoading(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    };

    if(error){
        return <div>Opps! An error occurred.</div>;
    }

    if(isPending){
        return <Loading/>;
    }

    return (
        <div className="mt-3">
            {showMessage && <Notification show={showMessage} close={()=>setShowMessage(false)} message={message}/>}
            <Table bordered>
                <thead>
                    <tr>
                        <th width="5%">SN</th>
                        <th width="70%">Tag</th>
                        <th width="10%">Verses</th>
                        <th width="15%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.tags.map((tag,i)=>{
                            return (
                                <tr key={i}>
                                    <td>{++i}</td>
                                    <td>
                                        {tag.value}
                                    </td> 
                                    <td>
                                        {tag.verses_count}
                                    </td>  
                                    <td className='text-center'>
                                        <Button size='sm' variant='outline-danger' onClick={()=>handleDelete(tag.id)}title="Delete" disabled={isLoading?"disabled":""}><FontAwesomeIcon icon={faTrash}/></Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        </div>
    )
}