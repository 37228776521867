import { configureStore } from '@reduxjs/toolkit';
import versesReducer from './verseSlice';
import categoriesReducer from './categorySlice';
import tagsReducer from './tagSlice';

export const store = configureStore({
  reducer: {
    verses: versesReducer,
    categories: categoriesReducer,
    tags: tagsReducer,
  },
})