import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import withPermission from './withPermission';

const MenuItem = ({ to, children }) => {
  return (
    <NavDropdown.Item as={Link} to={to}>{children}</NavDropdown.Item>
  );
};

const AuthorizedMenuItem = withPermission(MenuItem, "is:admin", false);

export default AuthorizedMenuItem;
