import { useLocation } from 'react-router-dom';
import { Button, Card, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ShowError from '../Error';

export default function VerseShow(){
    const location = useLocation();
    const [verse, setVerse] = useState();

    useEffect(()=>{
        if(location.state?.verse){
            setVerse(location.state.verse);
        } else{
            setVerse("");
        }
    },[location.state]);

    return (
        <Container className='main-container'>
            {
                !verse && <ShowError/>
            }
            { verse && 
                <Card className='mt-3'>
                    <Card.Body>
                        <Card.Title>{verse.title}</Card.Title>
                        <div dangerouslySetInnerHTML={{ __html: verse.description }}></div>
                        <Card.Text>
                        {
                            verse.tags && verse.tags.length > 0 && verse.tags.map((tag,i)=>{
                                return (
                                    <Button variant="secondary" size="sm" key={i} className="m-1 rounded-5">
                                    {tag.value}
                                    </Button>
                                );
                            })
                        }
                        </Card.Text>
                        <Card.Text className='text-end'>
                        {
                            verse.likes_count > 0 && <>
                                <FontAwesomeIcon icon={faHeart}/> {verse.likes_count}
                            </>
                        }
                        </Card.Text>
                    </Card.Body>
                </Card>
            }
        </Container>
    )
}