import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../store/categorySlice";
import Loading from "./Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, ListGroup } from "react-bootstrap";

export default function Category(){
    const dispatch = useDispatch();
    const { entities, loading, error } = useSelector(state=>state.categories);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        dispatch(getCategories());
    },[dispatch]);
    
    if (loading) {
      return <Loading />;
    }
    if (error) {
        return <div>Oops... {error}</div>;
    }

    const addQueryParamAndNavigate = (param) => {
      const searchParams = new URLSearchParams(location.search);
      if(searchParams.get('page')){
        searchParams.delete('page');
      }
      if(searchParams.get('category') === param){
        searchParams.delete('category');
      }else{
        searchParams.set('category', param);
      }
      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    };
    
    return(
        <>
          <h2 className="h6 text-start">Browse by category</h2>
          <ListGroup>
            {
              entities.total > 0 && entities.categories.map((category,i)=>{
                return (
                  <ListGroup.Item action key={i} onClick={()=> addQueryParamAndNavigate(category.name) } className="d-flex justify-content-between align-items-start">
                    {category.name}
                    <Badge bg="secondary" pill>
                    {category.verses_count}
                    </Badge>
                  </ListGroup.Item>
                );
              })
            }
          </ListGroup>
        </>
    );
}