import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { HelmetProvider } from 'react-helmet-async';
import Loading from "./components/Loading";
import Navigation from "./components/Navigation";
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import VerseShow from "./components/Verses/Show";
import Footer from "./components/Footer";
import CreateVerse from "./components/Verses/Create";
import { Subscription } from "./components/Subscription";
import AdminDashboard from "./components/Admin/Dashboard";
import "./App.css";

const App = () => {
  const { isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  
  if (isAuthenticated){
    if (error) {
      return <div>Oops... {error.message}</div>;
    }
  
    if (isLoading) {
      return <Loading />;
    }
    
    getAccessTokenSilently().then((token) => {
      if (token) {
        localStorage.setItem('token', token);
      }
    });
  }
  const helmetContext={};
  return (<HelmetProvider context={helmetContext}>
    <BrowserRouter>
      <div id="app" className={`d-flex flex-column h-100`}>
          <Navigation />
          <Routes>
            <Route path="/" exact element={<Dashboard/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/verses" element={<Dashboard/>} />
            <Route path="/verses/create" element={<CreateVerse/>} />
            <Route path="/verses/:slug" element={<VerseShow/>} />
            <Route path="/unsubscribe" element={<Subscription/>} />
            <Route path="/admin/:param" element={<AdminDashboard/>} />
          </Routes>
          <Footer/>
      </div>
    </BrowserRouter></HelmetProvider>
  );
};

export default App;
