import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';

const useFetch = (url, option) => {
    // const [refreshIndex, setRefreshIndex] = useState(0);
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const abortCont = new AbortController();
        setTimeout(async () => {
            try {
                let headers = {};
                if (isAuthenticated){
                    const token = await getAccessTokenSilently();
                    headers = { Authorization: `Bearer ${token}` }
                }
                fetch(url, { signal: abortCont.signal, headers })
                    .then(res => {
                        if (!res.ok) { // error coming back from server
                            throw Error('could not fetch the data for that resource');
                        }
                        return res.json();
                    })
                    .then(data => {
                        setIsPending(false);
                        setData(data.data);
                        setError(null);
                    })
                    .catch(err => {
                        if (err.name === 'AbortError') {
                            console.log('fetch aborted')
                        } else {
                            // auto catches network / connection error
                            setIsPending(false);
                            setError(err.message);
                        }
                    })
            } catch (error) {
                setIsPending(false);
                setError(error.message);
            }
        }, 500);

        // abort the fetch
        return () => abortCont.abort();
        // eslint-disable-next-line
    }, [option]);

    return { data, isPending, error};
}

export default useFetch;