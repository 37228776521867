import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, FormControl, InputGroup, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../store/categorySlice";
import { getTags } from "../../store/tagSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faEye, faQuestionCircle, faQuoteLeft, faSave, faSpinner, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import Editor from "./Editor";
import Notification from "../Notification";

const CreateVerse = () => {
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const { entities: categoryResponse } = useSelector(state => state.categories);
    const { entities: tagsResponse } = useSelector(state => state.tags);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        category: '',
        tags: []
    });
    const [errors, setErrors] = useState({});
    const [tagsInput, setTagsInput] = useState('');
    const [tagsSuggestions, setTagsSuggestions] = useState([]);
    const [mode, setMode] = useState("edit");
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '' });

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getTags());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleTagsInputChange = (value) => {
        setTagsInput(value.toLowerCase());
        if (value.trim() !== '') {
            const suggestions = tagsResponse.tags.filter(tag =>
                tag.value.toLowerCase().includes(value.toLowerCase())
            );
            setTagsSuggestions(suggestions);
        } else {
            setTagsSuggestions([]);
        }
    };

    const handleTagSelection = (tag) => {
        if (tag.trim() !== '' && !formData.tags.includes(tag)) {
            setFormData(prev => ({ ...prev, tags: [...prev.tags, tag] }));
            setErrors(prev => ({ ...prev, tags: '' }));
        }
        resetTagsInput();
    };

    const resetTagsInput = () => {
        setTagsInput('');
        setTagsSuggestions([]);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.title.trim()) newErrors.title = 'Title is required.';
        if (!formData.description.trim()) newErrors.description = 'Description is required.';
        if (!formData.category.trim()) newErrors.category = 'Category is required.';
        if (formData.tags.length === 0) newErrors.tags = 'Tags cannot be empty.';
        return newErrors;
    };

    const handleSubmit = async () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/verses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                resetForm();
                setNotification({ show: true, message: 'Thank you! Your content will be verified and published.' });
            } else {
                handleApiErrors(response);
            }
        } catch (error) {
            setNotification({ show: true, message: 'An error occurred, try again later.' });
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setFormData({ title: '', description: '', category: '', tags: [] });
        resetTagsInput();
    };

    const handleApiErrors = async (response) => {
        if (response.status === 422) {
            const errorData = await response.json();
            setNotification({ show: true, message: JSON.stringify(errorData) });
        } else {
            setNotification({ show: true, message: 'An error occurred, try again later.' });
        }
    };

    const toggleMode = () => {
        setMode(prevMode => (prevMode === "edit" ? "preview" : "edit"));
    };

    const removeTag = (tagToRemove) => {
        setFormData(prev => ({
            ...prev,
            tags: prev.tags.filter(tag => tag !== tagToRemove)
        }));
    };

    return (
        <Container fluid className="px-sm-5 main-container">
            {notification.show && <Notification show={notification.show} close={() => setNotification({ show: false, message: '' })} message={notification.message} />}
            <Card className="bg-transparent border-0">
                <Card.Header className="border-0 bg-transparent text-end">
                    <Button variant="secondary" className="rounded-1 me-2" onClick={toggleMode} disabled={isLoading}>
                        <FontAwesomeIcon icon={mode === "edit" ? faEye : faEdit} />
                        {mode === "edit" ? " Preview" : " Edit"}
                    </Button>
                    <Button variant="success" className="rounded-1" disabled={isLoading} onClick={handleSubmit}>
                        <FontAwesomeIcon icon={isLoading ? faSpinner : faSave} spin={isLoading} /> Save
                    </Button>
                </Card.Header>
                {mode === "preview" ? (
                    <VersePreview formData={formData} />
                ) : (
                    <VerseForm
                        formData={formData}
                        errors={errors}
                        handleChange={handleChange}
                        handleTagsInputChange={handleTagsInputChange}
                        tagsInput={tagsInput}
                        tagsSuggestions={tagsSuggestions}
                        handleTagSelection={handleTagSelection}
                        removeTag={removeTag}
                        categories={categoryResponse.categories}
                    />
                )}
            </Card>
        </Container>
    );
};

const VersePreview = ({ formData }) => (
    <Card className="text-start mb-2 card-verse">
        <Card.Body className="mb-0 pb-0">
            <figure>
                <blockquote className="blockquote">
                    <p className="h6">{formData.title}</p>
                    <small style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: formData.description }}></small>
                </blockquote>
            </figure>
        </Card.Body>
        <Card.Footer>
            <small><b>1</b> likes</small>
            <div className="float-end">
                <Button variant="outline-secondary" className="active mx-2 rounded-circle" title="Like" size="sm"><FontAwesomeIcon icon={faThumbsUp} /></Button>
                <Button size="sm" variant="outline-secondary" title="View"><FontAwesomeIcon icon={faQuoteLeft} /> View</Button>
            </div>
        </Card.Footer>
    </Card>
);

const VerseForm = ({ formData, errors, handleChange, handleTagsInputChange, tagsInput, tagsSuggestions, handleTagSelection, removeTag, categories }) => (
    <Card.Body>
        <div className="form-group">
            <Form.Control
                id="title"
                name="title"
                type="text"
                placeholder="Add title"
                aria-label="Title"
                value={formData.title}
                onChange={handleChange}
                className="rounded-0 border-0 border-bottom border-2"
            />
            {errors.title && <small className="text-danger">{errors.title}</small>}
        </div>
        <Row className="px-2">
            <Col sm={4} md={3} xl={2} className="order-sm-2 bg-transparent rounded-1 pt-3">
                <CategorySelect categories={categories} value={formData.category} onChange={handleChange} error={errors.category} />
                <TagInput
                    tagsInput={tagsInput}
                    tagsSuggestions={tagsSuggestions}
                    handleTagsInputChange={handleTagsInputChange}
                    handleTagSelection={handleTagSelection}
                    formData={formData}
                    removeTag={removeTag}
                    error={errors.tags}
                />
            </Col>
            <Col sm={8} md={9} xl={10} className="order-sm-1">
                <div className="form-group mb-3">
                    <Editor content={formData.description} setContent={value => handleChange({ target: { name: 'description', value } })} />
                    {errors.description && <small className="text-danger">{errors.description}</small>}
                </div>
            </Col>
        </Row>
    </Card.Body>
);

const CategorySelect = ({ categories, value, onChange, error }) => (
    <div className="form-group mb-3">
        <Form.Label htmlFor="category">Category</Form.Label>
        <Form.Select
            size="sm"
            aria-label="Category"
            name="category"
            className="rounded-1"
            value={value}
            onChange={onChange}
        >
            <option key={0} value="">Select category</option>
            {categories && categories.map((category, index) => (
                <option key={index + 1} value={category.id}>{category.name}</option>
            ))}
        </Form.Select>
        {error && <small className="text-danger">{error}</small>}
    </div>
);

const TagInput = ({ tagsInput, tagsSuggestions, handleTagsInputChange, handleTagSelection, formData, removeTag, error }) => (
    <div className="form-group mb-3">
        <Form.Label htmlFor="tags">Tags <FontAwesomeIcon className="text-info" icon={faQuestionCircle} title="Tags help people find your post, topics or keywords that best describe your post." /></Form.Label>
        <InputGroup size="sm">
            <FormControl
                type="text"
                className="rounded-0 border-0 border-bottom border-1"
                id="tags"
                autoComplete="off"
                value={tagsInput}
                onChange={(e) => handleTagsInputChange(e.target.value)}
                onBlur={() => { if(tagsSuggestions.length === 0){handleTagSelection(tagsInput);}}}
                placeholder="Enter tags"
            />
        </InputGroup>
        <ListGroup className="mt-1" style={{ maxHeight: "300px", overflowY: "scroll" }}>
            {tagsSuggestions.map((elem, i) => (
                <ListGroupItem key={i} onClick={() => handleTagSelection(elem.value)} action>
                    {elem.value}
                </ListGroupItem>
            ))}
        </ListGroup>
        {formData.tags.map((tag, i) => (
            <Badge
                as={Button}
                key={i}
                bg="secondary"
                className="me-1 border-0"
                onClick={() => removeTag(tag)}
                pill
            >
                {tag} <FontAwesomeIcon icon={faClose} />
            </Badge>
        ))}
        {error && <small className="text-danger">{error}</small>}
    </div>
);

export default CreateVerse;
