import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import Loading from './Loading';
import ShowError from "./Error";
import useFetch from "./useFetch";

const Profile = () => {
    const {data, isPending, error} = useFetch(`${process.env.REACT_APP_API_URL}/userinfo`);

    return (
        <Container className='main-container'>
            <Card className='mt-3 card-profile'>
                {
                    error && <ShowError message={error}/>
                }
                {
                    isPending && <Loading/>
                }
                {
                    data && <Row>
                        <Col md={4}>
                            <img src={data.picture} alt='profile_photo'/>
                        </Col>
                        <Col md={8}>
                            <Card.Title>{data.name}</Card.Title>
                            <Card.Text>{data.email}</Card.Text>
                            <Button variant="outline-primary" className='me-2' disabled>Total Verses <Badge bg='primary'>{data?.total_verses}</Badge></Button>
                            <Button variant="outline-danger" disabled>Total Likes <Badge bg='danger'>{data?.total_likes}</Badge></Button>
                        </Col>
                    </Row>
                }
            </Card>
        </Container>
    );
}
export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Loading />,
});