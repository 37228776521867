import { Badge, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import Notification from '../Notification';
import useFetch from '../useFetch';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

export function SubscriptionsList(props){
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const {data, isPending, error} = useFetch(`${process.env.REACT_APP_API_URL}/admin/subscriptions`, refresh);
    const [messageBody, setMessageBody] = useState("");

    const handleDelete = async(id) => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading2(true);
            fetch(`${process.env.REACT_APP_API_URL}/admin/subscriptions/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading2(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    setRefresh(new Date().getTime());
                    props.setRefresh(new Date().getTime());
                });
        } catch (error) {
            // console.log(error);
            setIsLoading2(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    };

    const handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            const token = await getAccessTokenSilently();
            const notification = { message: messageBody };
            setIsLoading1(true);
            fetch(`${process.env.REACT_APP_API_URL}/subscriptions/notify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify(notification)
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading1(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                });
        } catch (error) {
            // console.log(error);
            setIsLoading1(false);
            setShowMessage(true);
            setMessage(error.message);
        } finally{
            setMessageBody("");
        }
    }

    if(error){
        return <div>Opps! An error occurred.</div>;
    }

    if(isPending){
        return <Loading/>;
    }

    return (
        <Row className="mt-3">
            {showMessage && <Notification show={showMessage} close={()=>setShowMessage(false)} message={message}/>}
            <Col>
                <Card>
                    <Card.Body>
                        <Form onSubmit={handleSubmit} method="POST">
                            <div className="mb-3">
                                <label htmlFor="msg" className="form-label">Message</label>
                                <textarea className="form-control" id="msg" rows={5} value={messageBody} onChange={(e)=>setMessageBody(e.target.value)} />
                            </div>
                            <div className="d-grid gap-2">
                                <Button disabled={isLoading1?"disabled":""}>{isLoading1?<FontAwesomeIcon icon={faSpinner}/>:<FontAwesomeIcon icon={faEnvelope}/>} Send</Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Table bordered>
                    <thead>
                        <tr>
                            <th width="5%">SN</th>
                            <th width="70%">Email</th>
                            <th width="10%">Status</th>
                            <th width="15%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isPending && <tr><td colSpan={3} align="center"><Loading/></td></tr>
                        }
                        {
                            data && data.subscriptions.map((sub,i)=>{
                                return (
                                    <tr key={i}>
                                        <td>{++i}</td>
                                        <td>{sub.email}</td>  
                                        <td>{(sub.active)?<Badge bg="success">subscribed</Badge>:<Badge bg="warning">unsubscribed</Badge>}</td>  
                                        <td className="text-center">
                                            <Button size='sm' variant='outline-danger' onClick={()=>handleDelete(sub.id)} title="Delete" disabled={isLoading2?"disabled":""}><FontAwesomeIcon icon={faTrash}/></Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}