import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    loading: false,
    entities: {
      total: 0,
      verses:[]
    },
    error: null
}

export const getVerses = createAsyncThunk('getVerses', async (params, isAuthenticated=false)=>{
    let headers;
    if(isAuthenticated){
      const token = localStorage.getItem('token')||"";
      headers = { Authorization: `Bearer ${token}` }
    }
    let url = `${process.env.REACT_APP_API_URL}/verses`;
    if(params){
      url+='?'+params;
    }
    const response = await axios.get(url, { headers }).then(res => res.data);
    return response.data;
});

const verseSlice = createSlice({
    name: 'verses',
    initialState,
    reducers: {
        verseAdded(state, action) {
          state.entities.verses.unshift(action.payload);
          state.entities.total+=1;
        },
        verseUpdated(state, action) {
          const { id, title, description, category, tags, status } = action.payload;
          const existingVerse = state.entities.verses.find((verse) => verse.id === id);
          if (existingVerse) {
            existingVerse.title = title;
            existingVerse.description = description;
            existingVerse.category = category;
            existingVerse.tags = tags;
            existingVerse.liked = status;
            existingVerse.likes_count = (status)?existingVerse.likes_count+1:existingVerse.likes_count-1;
          }
        },
        verseDeleted(state, action) {
          const { id } = action.payload;
          const existingVerse = state.entities.verses.find((verse) => verse.id === id);
          if (existingVerse) {
            state.entities.verses = state.entities.verses.filter((verse) => verse.id !== id);
            state.entities.total-=1;
          }
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(getVerses.pending, (state)=>{
            state.loading = true;
        });
        builder.addCase(getVerses.fulfilled, (state, action)=>{
            state.loading = false;
            state.entities = action.payload;
            state.error = null;
        });
        builder.addCase(getVerses.rejected, (state, action)=>{
            state.loading = false;
            state.entities = {
              verses: [],
              total: 0
            };
            state.error = action.error.message
        });
    }
});

export const {verseAdded, verseUpdated, verseDeleted} = verseSlice.actions;
export default verseSlice.reducer;