import { useState } from "react";
import Notification from "./Notification";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Footer(){
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email })
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                });
        } catch (error) {
            // console.log(error);
            setIsLoading(false);
            setShowMessage(true);
            setMessage(error.message);
        } finally{
            setEmail("");
        }
    }

    return(
        <>
        {showMessage && <Notification show={showMessage} close={()=>setShowMessage(false)} message={message}/>}
        <footer className="p-3 bg-dark bg-gradient">
            <Container>
                <Row>
                    <Col md={8}>
                        <p className="text-light">© {new Date().getFullYear()} Knr Software</p>
                    </Col>
                    <Col md={4} className="text-end">
                        <h6 className="text-light">Subscribe To Our Newsletter</h6>
                        <Form className="d-flex" onSubmit={handleSubmit} method="POST">
                            <div className="input-group">
                                <input type="email" className="form-control" placeholder="Your email address" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                                <Button type="submit" variant="secondary" disabled={isLoading?"disabled":""}>{isLoading?<FontAwesomeIcon icon={faSpinner}/>:''} Subscribe</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </footer>
        </>
    );
}