import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

function Notification(props) {
  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header closeButton><FontAwesomeIcon icon={faExclamationTriangle}/></Modal.Header>
      <Modal.Body>
        <p>{props.message}</p>
      </Modal.Body>
    </Modal>
  );
}

export default Notification;