import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useLocation, useNavigate } from "react-router-dom";

export default function PaginationView (props){
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    let x=[];
    if(props.total > 1){
      for (let number = 1; number <= props.total; number++) {
        x.push(<Pagination.Item key={number} active={number === currentPage} onClick={()=>paginate(number)}>
          {number}
        </Pagination.Item>)
      }
    }
    setItems(x);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, props.limit, props.total])

  const paginate = (page) =>{
    setCurrentPage(page);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page);
  
    navigate({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    });
  }
  
    return(
        <div>
          {
            items && items.length > 0 && <Pagination>
            <Pagination.First onClick={()=>paginate(1)} />
            <Pagination.Prev onClick={()=>(currentPage>1)?paginate(currentPage-1):""}/>
            {items}
            <Pagination.Next onClick={()=>(currentPage<props.total)?paginate(currentPage+1):""} />
            <Pagination.Last onClick={()=>paginate(props.total)}/>
        </Pagination>
          }
        </div>
    )
}
