import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useEffect, useRef } from "react";

export default function Editor({ content, setContent }) {
    const theme = 'snow';
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", 'strike', "blockquote"],
            [{ align: [] }],
            [{ list: 'ordered'}, { list: 'bullet' }],
            [{ indent: '-1'}, { indent: '+1' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["link"],
            [{ color: [] }, { background: [] }],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };
    const placeholder = 'Write your content here...';
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "indent",
        "link",
        "color",
    ];

    const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

    const editorRef = useRef(null);

    useEffect(() => {
        if (quill) {
            editorRef.current = quill;
            quill.on('text-change', () => {
                setContent(quill.root.innerHTML);
            });
        }
    }, [quill, setContent]);

    return (
        <div style={{ minHeight: "50vh", border: 0 }} ref={quillRef} />
    );
}
