import { Badge, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import Notification from '../Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import useFetch from "../useFetch";

export function CategoriesList(props){
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [refresh, setRefresh] = useState(new Date().getTime());
    const {data, isPending, error} = useFetch(`${process.env.REACT_APP_API_URL}/admin/categories`, refresh);

    const handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            const token = await getAccessTokenSilently();
            const createCategory = { name, description };
            setIsLoading1(true);
            fetch(`${process.env.REACT_APP_API_URL}/admin/categories`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                },
                body: JSON.stringify(createCategory)
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading1(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    setRefresh(new Date().getTime());
                    props.setRefresh(new Date().getTime());
                });
        } catch (error) {
            // console.log(error);
            setIsLoading1(false);
            setShowMessage(true);
            setMessage(error.message);
        } finally{
            setName("");
            setDescription("");
        }
    }

    const handleDelete = async(id) => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading2(true);
            fetch(`${process.env.REACT_APP_API_URL}/admin/categories/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading2(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    setRefresh(new Date().getTime());
                    props.setRefresh(new Date().getTime());
                });
        } catch (error) {
            // console.log(error);
            setIsLoading2(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    };

    if(error){
        return <div>Opps! An error occurred.</div>;
    }

    if(isPending){
        return <Loading/>;
    }

    return (
        <div className="mt-3">
            {showMessage && <Notification show={showMessage} close={()=>setShowMessage(false)} message={message}/>}
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={handleSubmit} method='POST'>
                                <div className="mb-3">
                                    <Form.Label htmlFor='name'>Name</Form.Label>
                                    <Form.Control type='text' value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter category name'/>
                                </div>
                                <div className="mb-3">
                                    <Form.Label htmlFor='description'>Description</Form.Label>
                                    <Form.Control type='text' value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Enter category name'/>
                                </div>
                                <div className="d-grid gap-2">
                                    <Button type="submit" disabled={isLoading1?"disabled":""}>{isLoading1?<FontAwesomeIcon icon={faSpinner}/>:<FontAwesomeIcon icon={faSave}/>} Submit</Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th width="5%">SN</th>
                                <th width="80%">Category</th>
                                <th width="15%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.categories.map((category,i)=>{
                                    return (
                                        <tr key={i}>
                                            <td>{++i}</td>
                                            <td>{category.name}
                                                <span className='float-end'><Badge>{category.verses_count}</Badge></span>
                                            </td>  
                                            <td className="text-center">
                                                <Button size='sm' variant='outline-danger' onClick={()=>handleDelete(category.id)} title="Delete" disabled={isLoading2?"disabled":""}><FontAwesomeIcon icon={faTrash}/></Button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}