import {Card, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faThumbsUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import Loading from "../Loading";
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { getVerses, verseDeleted, verseUpdated } from '../../store/verseSlice';
import Notification from '../Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import PaginationView from '../Pagination';

export default function VersesList(){
    const location = useLocation();
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const dispatch = useDispatch();
    const { entities } = useSelector(state=>state.verses);
    const loading = useSelector((state) => state.loading);
    const error = useSelector((state) => state.error);
    const [myVerse, setMyVerse] = useState(false);
    const navigate = useNavigate();
    const [perPage] = useState(10);

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('limit', perPage);
        if(location.state?.my_verse){
            if(isAuthenticated){
                queryParams.set('added_by', user.sub);
            }
            setMyVerse(true);
        }else{
            setMyVerse(false);
        }
        dispatch(getVerses(queryParams.toString(), isAuthenticated));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, location, perPage]);

    const handleDelete = async(id) => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading(true);
            fetch(`${process.env.REACT_APP_API_URL}/verses/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`
                }
            }).then(response => response.json())
                .then(ret => {
                    setIsLoading(false);
                    setShowMessage(true);
                    setMessage(ret.message);
                    dispatch(verseDeleted({ id }));
                });
        } catch (error) {
            // console.log(error);
            setIsLoading(false);
            setShowMessage(true);
            setMessage(error.message);
        }
    };

    const handleReact = async (verse, status)=>{
        if(!verse.liked){
            try {
                const token = await getAccessTokenSilently();
                setIsLoading(true);
                fetch(`${process.env.REACT_APP_API_URL}/verses/${verse.id}/react`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: `Bearer ${token}`
                    },
                }).then(response => response.json())
                    .then(ret => {
                        setIsLoading(false);
                        dispatch(verseUpdated({id:verse.id, ...verse, status}));
                    });
            } catch (error) {
                setIsLoading(false);
                setShowMessage(true);
                setMessage(error.message);
            }
        }
    }

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <div>Oops... {error}</div>;
    }
    return(
        <>
            <h1 className="h6 text-start">{myVerse? "My verses":"Popular verses" }</h1>
            {
                showMessage && <Notification show={showMessage} close={()=>setShowMessage(false)} message={message}/>
            }
            {
                entities && entities.verses.map((elem,i)=>{
                    return (
                        <Card className="text-start mb-2 card-verse" key={i}>
                            <Card.Body className='mb-0 pb-0'>
                                <figure>
                                    <blockquote className="blockquote">
                                        <p className='h6'>{elem.title}</p>
                                        <small style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: elem.description }}></small>
                                    </blockquote>
                                </figure>
                            </Card.Body>
                            <Card.Footer>
                                {
                                    elem.likes_count > 1 && <small>
                                        <b>{elem.likes_count}</b> likes
                                    </small>
                                }
                                <div className='float-end'>
                                    {
                                        myVerse && <Button className='mx-2' variant="outline-danger" size="sm" onClick={()=>handleDelete(elem.id)} title="Delete"><FontAwesomeIcon icon={faTrash} /> Delete</Button>
                                    }
                                    {
                                        !myVerse && <><Button variant="outline-secondary" className={(elem.liked)?"active mx-2 rounded-circle":"mx-2 rounded-circle"} title={(elem.liked)?"Liked":"Like"} size="sm" onClick={()=>(isAuthenticated)? handleReact(elem, true): loginWithRedirect({
                                            appState: { returnTo: window.location.pathname }
                                        })} disabled={isLoading?"disabled":""}><FontAwesomeIcon icon={faThumbsUp} /></Button>
                                        <Button size='sm' variant='outline-secondary' onClick={()=> navigate(`/verses/${elem.slug}`, {state: {verse: elem}})} title='View'><FontAwesomeIcon icon={faQuoteLeft}/> View</Button>
                                        {/* <Button size='sm' variant='outline-secondary' onClick={()=> navigate('/verses/show', {state: {verseId: elem.id}})} title='View'><FontAwesomeIcon icon={faShare}/> View</Button> */}
                                        </>
                                    }
                                </div>
                            </Card.Footer>
                        </Card>
                    )
                })
            }
            <PaginationView total={Math.ceil(entities.total/perPage)} limit={perPage}/>
        </>
    );
}